<template>
  <div class="app">
    <v-card flat width="500">
      <v-card-text class="d-flex">
        <div style="flex: 1;display: flex;flex-direction: column;justify-content: center;align-items: center">
          <v-icon size="180">mdi-alert-outline</v-icon>
<!--          <v-btn elevation="0" width="150" color="primary">回首页</v-btn>-->
        </div>
        <div style="flex: 1;display: flex;flex-direction: column;justify-content: space-evenly;">
          <h2>页面未找到</h2>
          <p>很抱歉，我们找不到您请求的页面。</p>
          <div>
            <v-btn elevation="0" width="100" class="mr-2" @click="$router.push('/admin')">回首页</v-btn>
            <v-btn elevation="0" width="100" @click="$router.go(-1) ">回到上一页</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "invalid"
}
</script>

<style scoped>
  .app{
    background: #F3F3F3;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>